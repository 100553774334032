import axios, { AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import Config from "config";

interface ConfigType extends AxiosRequestConfig {}

const apiInstance = axios.create({
  baseURL: Config.API_BASE_URL,
});

apiInstance.interceptors.request.use(
  function (config: InternalAxiosRequestConfig) {
    console.log(`Making request to: ${config.baseURL}${config.url}`);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const get = <T>(url: string, config: ConfigType = {}): Promise<AxiosResponse<T>> =>
  apiInstance.get(url, config);

const post = <T>(url: string, data: unknown, config: ConfigType = {}): Promise<AxiosResponse<T>> =>
  apiInstance.post(url, data, config);

const put = <T>(url: string, data: unknown, config: ConfigType = {}): Promise<AxiosResponse<T>> =>
  apiInstance.put(url, data, config);

const patch = <T>(url: string, data: unknown, config: ConfigType = {}): Promise<AxiosResponse<T>> =>
  apiInstance.patch(url, data, config);

const remove = <T>(url: string, config: ConfigType = {}): Promise<AxiosResponse<T>> =>
  apiInstance.delete(url, config);

// Login method (proxied through server.js)
const login = async (email: string, password: string): Promise<void> => {
  try {
    await post("/api/login", { email, password });
    // The server handles saving the token, so we don't need to do anything here
  } catch (error) {
    console.error("Login failed:", error);
    throw error;
  }
};

// Logout method (if needed)
const logout = async (): Promise<void> => {
  try {
    await post("/api/logout", {});
    // The server handles removing the token
  } catch (error) {
    console.error("Logout failed:", error);
    throw error;
  }
};

export default { get, post, put, patch, remove, login, logout };
