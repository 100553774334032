import ApiService from "./ApiService";

// New function to get best Walmart products
const getWalmartBest = async (): Promise<any> => {
  try {
    const response = await ApiService.get("/api/walmart/best");
    console.log("Received response for Walmart Best:", response.status, response.data); // Log response for debugging
    return response.data;
  } catch (error) {
    console.error("Error fetching Walmart Best products:", error);
    throw error;
  }
};

export default getWalmartBest;
