// @mui material components
import Icon from "@mui/material/Icon";

const pageRoutes = [
  {
    name: "Capabilities",
    columns: 2,
    rowsPerColumn: 1,
    collapse: [
      {
        name: "Analytics",
        icon: <Icon>dashboard</Icon>,
        collapse: [
          {
            name: "Dashboard",
            route: "/dashboards/analytics",
          },
          {
            name: "Top Deals",
            route: "/dashboards/analytics",
          },
          {
            name: "Coupons",
            route: "/dashboards/analytics",
          },
        ],
      },
      {
        name: "Account",
        icon: <Icon>account_balance</Icon>,
        collapse: [
          {
            name: "settings",
            route: "/pages/account/setting",
          },
        ],
      },
    ],
  },
  {
    name: "docs",
    collapse: [
      {
        name: "getting started",
        href: "https://linkscopic.com/space/extension",
        description: "All about overview, quick start, and contents",
        icon: <Icon>article</Icon>,
      },
      {
        name: "foundation",
        href: "https://linkscopic.com/space/extension",
        description: "All about how to find profitable products online",
        icon: <Icon>grading</Icon>,
      },
    ],
  },
];

export default pageRoutes;
