import ApiService from "./ApiService";

// New function to get coupons
const getCoupon = async (): Promise<any> => {
  try {
    const response = await ApiService.get("/api/coupon");
    console.log("Received response for Coupons:", response.status, response.data); // Log response for debugging
    return response.data;
  } catch (error) {
    console.error("Error fetching coupons:", error);
    throw error;
  }
};

export default getCoupon;
