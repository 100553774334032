const defaultConfig = {
  API_BASE_URL: "https://dealsoil.com",
};

const Config = {
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL || defaultConfig.API_BASE_URL,

  // Function to set the bearer token in localStorage
  setBearerToken: (token: string) => {
    localStorage.setItem("linkscopicToken", token);
  },

  // Function to get the bearer token from localStorage
  getBearerToken: (): string | null => {
    return localStorage.getItem("linkscopicToken");
  },

  // Function to remove the bearer token from localStorage
  removeBearerToken: () => {
    localStorage.removeItem("linkscopicToken");
  },
};

export default Config;
