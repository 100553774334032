// File: src/layouts/product-research/virtual-assistant/index.tsx

import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Grid,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Paper,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import MDBox from "components/MDBox";
import { sendMessage } from "services/VirtualAssistant";

interface Message {
  text: string;
  isUser: boolean;
}

function VirtualAssistant() {
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const handleSend = async () => {
    if (input.trim() === "" || isLoading) return;

    // Add user message
    setMessages((prevMessages) => [...prevMessages, { text: input, isUser: true }]);
    setInput("");
    setIsLoading(true);

    try {
      const aiResponse = await sendMessage(input);
      setMessages((prevMessages) => [...prevMessages, { text: aiResponse, isUser: false }]);
    } catch (error) {
      console.error("Error getting AI response:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: "Sorry, I couldn't process your request. Please try again.", isUser: false },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <Card>
              <MDBox p={3}>
                <Typography variant="h4" gutterBottom>
                  Virtual Assistant
                </Typography>
                <Paper
                  elevation={3}
                  style={{
                    height: "400px",
                    overflowY: "auto",
                    marginBottom: "20px",
                    padding: "20px",
                  }}
                >
                  <List>
                    {messages.map((message, index) => (
                      <ListItem key={index} alignItems="flex-start">
                        <ListItemText
                          primary={message.isUser ? "You" : "Assistant"}
                          secondary={message.text}
                          primaryTypographyProps={{
                            fontWeight: "bold",
                            color: message.isUser ? "primary" : "secondary",
                          }}
                        />
                      </ListItem>
                    ))}
                    {isLoading && (
                      <ListItem>
                        <ListItemText secondary="Assistant is typing..." />
                      </ListItem>
                    )}
                  </List>
                  <div ref={messagesEndRef} />
                </Paper>
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Type your message here..."
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      onKeyPress={handleKeyPress}
                      disabled={isLoading}
                      multiline
                      maxRows={4}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      endIcon={<SendIcon />}
                      onClick={handleSend}
                      disabled={isLoading}
                      style={{ height: "100%", color: "white" }}
                    >
                      Send
                    </Button>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default VirtualAssistant;
