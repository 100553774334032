import ApiService from "./ApiService";

// New function to get best Amazon products
const getAmazonBest = async (): Promise<any> => {
  try {
    const response = await ApiService.get("/api/amazon/best");
    console.log("Received response for Amazon Best:", response.status, response.data); // Log response for debugging
    return response.data;
  } catch (error) {
    console.error("Error fetching Amazon Best products:", error);
    throw error;
  }
};

export default getAmazonBest;
