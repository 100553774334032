import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import DataTable from "components/Tables/DataTable";
import getAmazonBest from "services/GetAmazonBest";
import getWalmartBest from "services/GetWalmartBest";
import dealsTableData from "layouts/product-research/deals/data/amazonTableData";
import walmartTableData from "layouts/product-research/deals/data/walmartTableData";

function DealsDataTables(): JSX.Element {
  const [amazonData, setAmazonData] = useState([]);
  const [walmartData, setWalmartData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const fetchAmazonBest = async () => {
      try {
        const result = await getAmazonBest();
        if (result && result.products) {
          const transformedData = result.products.map((product: any) => ({
            category: "N/A",
            store: product.store || "N/A",
            imageurl: <img src={product.imageurl || "N/A"} alt={product.name} width="50" />,
            name: product.name,
            asin: product.sku || "N/A",
            finalPrice:
              product.pricecurrency === "USD" ? `$${(product.finalprice / 100).toFixed(2)}` : "N/A",
            amazonPrice:
              product.pricecurrency === "USD" ? `$${(product.price / 100).toFixed(2)}` : "N/A",
            walmartPrice: "N/A",
            updatedAt: new Date(product.updated_at).toLocaleString(),
          }));
          setAmazonData(transformedData);
        }
      } catch (error) {
        console.error("Error fetching Amazon best products:", error);
      }
    };

    const fetchWalmartBest = async () => {
      try {
        const result = await getWalmartBest();
        if (result && result.products) {
          const transformedData = result.products.map((product: any) => ({
            store: product.store || "N/A",
            imageurl: <img src={product.imageurl || "N/A"} alt={product.name} width="50" />,
            name: product.name,
            sku: product.sku || "N/A",
            finalprice:
              product.pricecurrency === "USD" ? `$${(product.finalprice / 100).toFixed(2)}` : "N/A",
            price: product.pricecurrency === "USD" ? `$${(product.price / 100).toFixed(2)}` : "N/A",
            discount: `${product.discount}%`,
            upcorean: product.upcorean || "N/A",
            updated_at: new Date(product.updated_at).toLocaleString(),
          }));
          setWalmartData(transformedData);
        }
      } catch (error) {
        console.error("Error fetching Walmart best products:", error);
      }
    };

    fetchAmazonBest();
    fetchWalmartBest();
  }, []);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  const amazonTableData = {
    columns: dealsTableData.columns,
    rows: amazonData,
  };

  const updatedWalmartTableData = {
    columns: walmartTableData.columns,
    rows: walmartData,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Deals
            </MDTypography>
            <MDTypography variant="button" color="text">
              Top deals from Amazon and Walmart.
            </MDTypography>
          </MDBox>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="Amazon Deals" />
            <Tab label="Walmart Deals" />
          </Tabs>
          {activeTab === 0 && <DataTable table={amazonTableData} canSearch />}
          {activeTab === 1 && <DataTable table={updatedWalmartTableData} canSearch />}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DealsDataTables;
