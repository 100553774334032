interface DataTableRow {
  store_country: string;
  label: string;
  code: string;
  expires_at_human: string;
}

const couponsTableData = {
  columns: [
    { Header: "Expires At", accessor: "expires_at_human", width: "20%" },
    { Header: "Code", accessor: "code", width: "20%" },
    { Header: "Store", accessor: "store_country", width: "20%" },
    { Header: "Label", accessor: "label", width: "20%" },
  ],
  rows: [] as DataTableRow[], // Explicitly type the rows array
};

export default couponsTableData;
