import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Card, TextField, Button, Grid, CircularProgress, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import DataTable from "components/Tables/DataTable";

import getAmazonProducts from "services/GetAmazonProducts";
import getWalmartProducts from "services/GetWalmartProducts";
import getAmazonPrice, { AmazonPriceResponse, Offer } from "services/GetAmazonPrice";

function SellDataTables(): JSX.Element {
  const location = useLocation();
  const [upc, setUpc] = useState("");
  const [ean, setEan] = useState("");
  const [title, setTitle] = useState("");
  const [amazonData, setAmazonData] = useState([]);
  const [walmartData, setWalmartData] = useState([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (field: "upc" | "ean" | "title", value: string) => {
    setError(null);
    if (value) {
      if (field === "upc") {
        setUpc(value);
        setEan("");
        setTitle("");
      } else if (field === "ean") {
        setUpc("");
        setEan(value);
        setTitle("");
      } else {
        setUpc("");
        setEan("");
        setTitle(value);
      }
    } else {
      // If the field is cleared, just update that field
      if (field === "upc") setUpc("");
      else if (field === "ean") setEan("");
      else setTitle("");
    }
  };

  const handleSearch = useCallback(async () => {
    let query = "";
    let flavor: "upc" | "ean" | "title" = "upc";

    if (upc) {
      query = upc;
      flavor = "upc";
    } else if (ean) {
      query = ean;
      flavor = "ean";
    } else if (title) {
      query = title;
      flavor = "title";
    } else {
      setError("Please enter a UPC, EAN, or Title to search.");
      return;
    }

    setError(null);
    setLoading(true);
    try {
      const amazonResult = await getAmazonProducts({ q: query, flavor });
      const amazonDataWithPrices = await Promise.all(
        amazonResult.items.map(async (item: any) => {
          const priceData: AmazonPriceResponse = await getAmazonPrice(item.asin);
          const buyBoxOffer = priceData.payload.Offers.find((offer: Offer) => offer.IsBuyBoxWinner);
          const buyBoxPrice = buyBoxOffer ? buyBoxOffer.ListingPrice.Amount : null;
          return {
            ...item,
            buyBoxPrice,
          };
        })
      );
      setAmazonData(amazonDataWithPrices);

      const walmartResult = await getWalmartProducts({ q: query, flavor });
      setWalmartData(walmartResult.items || []);
    } catch (error) {
      console.error("Error fetching product data:", error);
      setError("An error occurred while fetching product data. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [upc, ean, title]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const queryParam = searchParams.get("query");
    const flavorParam = searchParams.get("flavor");

    if (queryParam && flavorParam) {
      if (flavorParam === "upc") setUpc(queryParam);
      else if (flavorParam === "ean") setEan(queryParam);
      else if (flavorParam === "title") setTitle(queryParam);
      handleSearch();
    }
  }, [location.search, handleSearch]);

  const formatPrice = (price: string | number) => {
    const numPrice = typeof price === "string" ? parseFloat(price) : price;
    return numPrice ? `$${numPrice.toFixed(2)}` : "N/A";
  };

  const TruncatedText = ({ text }: { text: string }) => (
    <Tooltip title={text}>
      <MDTypography
        variant="body2"
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
        }}
      >
        {text}
      </MDTypography>
    </Tooltip>
  );

  const amazonTableData = {
    columns: [
      { Header: "Image", accessor: "image", width: "10%" },
      { Header: "ASIN", accessor: "asin", width: "15%" },
      { Header: "Title", accessor: "title", width: "40%" },
      { Header: "Brand", accessor: "brand", width: "20%" },
      { Header: "Price", accessor: "price", width: "15%" },
    ],
    rows: amazonData.map((item: any) => ({
      image: (
        <img
          src={item.images?.[0]?.images?.[0]?.link || "https://via.placeholder.com/50"}
          alt={item.attributes?.item_name?.[0]?.value || "Product"}
          style={{ width: "50px", height: "50px", objectFit: "cover", borderRadius: "4px" }}
        />
      ),
      asin: (
        <MDTypography
          component="a"
          href={`https://www.amazon.com/dp/${item.asin}`}
          target="_blank"
          rel="noopener noreferrer"
          variant="body2"
          color="info"
        >
          {item.asin || "N/A"}
        </MDTypography>
      ),
      title: <TruncatedText text={item.attributes?.item_name?.[0]?.value || "N/A"} />,
      brand: item.attributes?.brand?.[0]?.value || "N/A",
      price: formatPrice(item.buyBoxPrice || item.attributes?.list_price?.[0]?.value),
    })),
  };

  const walmartTableData = {
    columns: [
      { Header: "Image", accessor: "image", width: "10%" },
      { Header: "Item ID", accessor: "itemId", width: "15%" },
      { Header: "Title", accessor: "title", width: "40%" },
      { Header: "Brand", accessor: "brand", width: "20%" },
      { Header: "Price", accessor: "price", width: "15%" },
    ],
    rows: walmartData.map((item: any) => ({
      image: (
        <img
          src={item.images?.[0]?.url || "https://via.placeholder.com/50"}
          alt={item.title || "Product"}
          style={{ width: "50px", height: "50px", objectFit: "cover", borderRadius: "4px" }}
        />
      ),
      itemId: (
        <MDTypography
          component="a"
          href={`https://www.walmart.com/ip/${item.itemId}`}
          target="_blank"
          rel="noopener noreferrer"
          variant="body2"
          color="info"
        >
          {item.itemId || "N/A"}
        </MDTypography>
      ),
      title: <TruncatedText text={item.title || "N/A"} />,
      brand: item.brand || "N/A",
      price: formatPrice(item.price?.amount || item.salePrice),
    })),
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox p={3} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium" mb={2}>
                  Product Search
                </MDTypography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="UPC"
                      value={upc}
                      onChange={(e) => handleInputChange("upc", e.target.value)}
                      fullWidth
                      margin="normal"
                      error={Boolean(error && upc)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="EAN"
                      value={ean}
                      onChange={(e) => handleInputChange("ean", e.target.value)}
                      fullWidth
                      margin="normal"
                      error={Boolean(error && ean)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Title"
                      value={title}
                      onChange={(e) => handleInputChange("title", e.target.value)}
                      fullWidth
                      margin="normal"
                      error={Boolean(error && title)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSearch}
                      fullWidth
                      disabled={loading}
                      style={{
                        height: "56px",
                        color: "white",
                      }}
                    >
                      {loading ? <CircularProgress size={24} color="inherit" /> : "Search"}
                    </Button>
                  </Grid>
                </Grid>
                {error && (
                  <MDBox mt={2}>
                    <MDTypography color="error">{error}</MDTypography>
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox p={3} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium" mb={2}>
                  Amazon Results
                </MDTypography>
                <DataTable
                  table={amazonTableData}
                  canSearch
                  entriesPerPage={{ defaultValue: 10, entries: [5, 10, 25, 50] }}
                />
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox p={3} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium" mb={2}>
                  Walmart Results
                </MDTypography>
                <DataTable
                  table={walmartTableData}
                  canSearch
                  entriesPerPage={{ defaultValue: 10, entries: [5, 10, 25, 50] }}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default SellDataTables;
