import ApiService from "./ApiService";

interface AmazonProductsParams {
  q: string;
  flavor: "upc" | "ean" | "asin" | "title";
}

const getAmazonProducts = async (params: AmazonProductsParams): Promise<any> => {
  try {
    const response = await ApiService.get("/api/amazon/query", { params });
    console.log("Received response for Amazon Products:", response.status, response.data); // Log response for debugging
    return response.data;
  } catch (error) {
    console.error("Error fetching Amazon Products:", error);
    throw error;
  }
};

export default getAmazonProducts;
