interface WalmartDataTableRow {
  discount: number;
  finalprice: number;
  id: string;
  imageurl: string;
  link: string;
  name: string;
  price: number;
  pricecurrency: string;
  sku: string;
  store: string;
  upcorean: string;
  updated_at: number;
}

const walmartTableData = {
  columns: [
    { Header: "Store", accessor: "store", width: "10%" },
    { Header: "Image", accessor: "imageurl", width: "7%" },
    { Header: "Name", accessor: "name", width: "15%" },
    { Header: "SKU", accessor: "sku", width: "7%" },
    { Header: "Final Price", accessor: "finalprice", width: "7%" },
    { Header: "Original Price", accessor: "price", width: "7%" },
    { Header: "Discount", accessor: "discount", width: "7%" },
    { Header: "UPC/EAN", accessor: "upcorean", width: "10%" },
    { Header: "Updated At", accessor: "updated_at", width: "10%" },
  ],
  rows: [] as WalmartDataTableRow[], // Explicitly type the rows array
};

export default walmartTableData;
