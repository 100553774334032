// File: src/services/AIService.ts

import axios from "axios";

const API_URL = "YOUR_AI_SERVICE_ENDPOINT";

export const sendMessage = async (message: string): Promise<string> => {
  try {
    const response = await axios.post(API_URL, { message });
    return response.data.reply;
  } catch (error) {
    console.error("Error sending message to AI service:", error);
    throw error;
  }
};
