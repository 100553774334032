import ApiService from "./ApiService";

interface WalmartProductsParams {
  q: string;
  flavor: "upc" | "ean" | "sku" | "title"; // Note: Changed 'asin' to 'sku' for Walmart
}

const getWalmartProducts = async (params: WalmartProductsParams): Promise<any> => {
  try {
    const response = await ApiService.get("/api/walmart/query", { params });
    console.log("Received response for Walmart Products:", response.status, response.data); // Log response for debugging
    return response.data;
  } catch (error) {
    console.error("Error fetching Walmart Products:", error);
    throw error;
  }
};

export default getWalmartProducts;
