import ApiService from "./ApiService";

interface Price {
  Amount: number;
  CurrencyCode: string;
}

export interface Offer {
  IsBuyBoxWinner: boolean;
  ListingPrice: Price;
  // Add other properties as needed
}

export interface AmazonPriceResponse {
  payload: {
    ASIN: string;
    Offers: Offer[];
    // Add other properties as needed
  };
}

interface ApiResponse {
  data: AmazonPriceResponse;
  status: number;
}

const getAmazonPrice = async (asin: string): Promise<AmazonPriceResponse> => {
  try {
    const response: ApiResponse = await ApiService.get("/api/amazon/query", {
      params: {
        q: asin,
        flavor: "price",
      },
    });
    console.log("Received response for Amazon Price:", response.status, response.data); // Log response for debugging

    if (response.data && response.data.payload) {
      return response.data;
    } else {
      throw new Error("Invalid response format");
    }
  } catch (error) {
    console.error("Error fetching Amazon price:", error);
    throw error;
  }
};

export default getAmazonPrice;
