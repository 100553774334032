import React, { useEffect, useState } from "react";
// @mui/material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import DataTable from "components/Tables/DataTable";

// Service
import getCoupon from "services/GetCoupon";

// Data
import couponsTableData from "./data/dataTableData";

function CouponsDataTables(): JSX.Element {
  const [data, setData] = useState([]);
  const [onlyWithCode, setOnlyWithCode] = useState(true); // Set default to true
  const [copiedCoupon, setCopiedCoupon] = useState("");

  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const result = await getCoupon();
        if (result && result.items) {
          const transformedData = result.items.map((coupon: any) => ({
            store_country: coupon.store_country || "N/A",
            label: coupon.label || "N/A",
            code: coupon.code ? (
              <span
                style={{ cursor: "pointer", color: "blue" }}
                onClick={() => {
                  navigator.clipboard.writeText(coupon.code);
                  setCopiedCoupon(coupon.code);
                  setTimeout(() => setCopiedCoupon(""), 2000); // Clear message after 2 seconds
                }}
              >
                {coupon.code}
              </span>
            ) : (
              "N/A"
            ),
            expires_at_human:
              coupon.expires_at_human || new Date(coupon.expires_at).toLocaleString(),
          }));
          setData(transformedData);
        }
      } catch (error) {
        console.error("Error fetching coupons:", error);
      }
    };

    fetchCoupons();
  }, []);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOnlyWithCode(event.target.checked);
  };

  const filteredData = data.filter((item) => (onlyWithCode ? item.code !== "N/A" : true));

  const updatedDataTableData = {
    columns: couponsTableData.columns,
    rows: filteredData,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Available Coupons
            </MDTypography>
            <MDTypography variant="button" color="text">
              Avail discounts from various stores using these coupons.
            </MDTypography>
          </MDBox>
          <MDBox p={3} display="flex" justifyContent="space-between" alignItems="center">
            <FormControlLabel
              control={<Checkbox checked={onlyWithCode} onChange={handleCheckboxChange} />}
              label="Show only items with a code"
            />
          </MDBox>
          <Box position="relative">
            {copiedCoupon && (
              <Box
                position="absolute"
                top={-30}
                left="50%"
                sx={{ transform: "translateX(-50%)" }}
                zIndex={1}
              >
                <MDTypography variant="caption" color="success">
                  Coupon Copied!
                </MDTypography>
              </Box>
            )}
            <DataTable table={updatedDataTableData} canSearch />
          </Box>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CouponsDataTables;
