// Layouts
import Analytics from "layouts/dashboards/analytics";
import Settings from "layouts/pages/account/settings";
import DealsDataTables from "layouts/product-research/deals";
import CouponsDataTables from "layouts/product-research/coupons";
import SellDataTables from "layouts/product-research/sell";
import VirtualAssistant from "layouts/product-research/virtual-assistant";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignUpCover from "layouts/authentication/sign-up/cover";
import ResetCover from "layouts/authentication/reset-password/cover";

// components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-3.jpg";

const routes = [
  {
    type: "collapse",
    name: "Test User",
    key: "user",
    icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    collapse: [
      {
        name: "Settings",
        key: "profile-settings",
        route: "/pages/account/settings",
        component: <Settings />,
      },
      {
        name: "Logout",
        key: "logout",
        route: "/authentication/sign-in/basic",
        component: <SignInBasic />,
      },
    ],
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Analytics",
        key: "analytics",
        route: "/dashboards/analytics",
        component: <Analytics />,
      },
    ],
  },
  { type: "divider", key: "divider-0" },

  {
    type: "collapse",
    name: "Product Research",
    key: "pages",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Coupons",
        key: "coupons",
        route: "/product-research/coupons",
        component: <CouponsDataTables />,
      },
      {
        name: "Deals",
        key: "deals",
        route: "/product-research/deals",
        component: <DealsDataTables />,
      },
      {
        name: "Sell",
        key: "sell",
        route: "/product-research/sell",
        component: <SellDataTables />,
      },
      {
        name: "Virtual Assistant",
        key: "virtual-assistant",
        route: "/product-research/virtual-assistant",
        component: <VirtualAssistant />,
      },
    ],
  },
  // Authentication routes marked as hidden
  {
    type: "collapse",
    name: "Authentication",
    key: "authentication",
    icon: <Icon fontSize="medium">content_paste</Icon>,
    hidden: true, // This will be used to hide the entire authentication group
    collapse: [
      {
        name: "Sign In",
        key: "sign-in",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/sign-in/basic",
            component: <SignInBasic />,
            hidden: true,
          },
        ],
      },
      {
        name: "Sign Up",
        key: "sign-up",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/sign-up/cover",
            component: <SignUpCover />,
            hidden: true,
          },
        ],
      },
      {
        name: "Reset Password",
        key: "reset-password",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/reset-password/cover",
            component: <ResetCover />,
            hidden: true,
          },
        ],
      },
    ],
  },
  { type: "divider", key: "divider-1" },
  { type: "title", title: "Need Help", key: "need-help" },
  {
    type: "collapse",
    name: "Support",
    key: "support",
    icon: <Icon fontSize="medium">upcoming</Icon>,
    collapse: [
      {
        name: "Report an Issue",
        key: "issue",
        route: "/support/report-an-issue",
        component: <Analytics />,
      },
      {
        name: "Request a Feature",
        key: "feature",
        route: "/support/request-a-feature",
        component: <Analytics />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Docs",
    key: "docs",
    icon: <Icon fontSize="medium">upcoming</Icon>,
    collapse: [
      {
        name: "Getting Started",
        key: "getting-started",
        collapse: [
          {
            name: "Quick Start",
            key: "quick-start",
            href: "https://www.creative-tim.com/learning-lab/react/quick-start/material-dashboard/",
          },
        ],
      },
      {
        name: "Foundation",
        key: "foundation",
        collapse: [
          {
            name: "Colors",
            key: "colors",
            href: "https://www.creative-tim.com/learning-lab/react/colors/material-dashboard/",
          },
        ],
      },
    ],
  },
];

export default routes;
