import React, { createContext, useContext, useState, ReactNode, useCallback } from "react";
import axios from "axios";
import Config from "config";

interface AuthContextType {
  isAuthenticated: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!Config.getBearerToken());

  const login = useCallback(async (email: string, password: string) => {
    const config = {
      method: "post",
      url: `${Config.API_BASE_URL}/api/login`, // Use configuration file for base URL
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: JSON.stringify({ email, password }),
    };

    try {
      const response = await axios.request(config);
      const { bearer } = response.data;
      Config.setBearerToken(bearer);
      localStorage.setItem("email", email);
      localStorage.setItem("password", password);
      setIsAuthenticated(true);
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    }
  }, []);

  const logout = () => {
    setIsAuthenticated(false);
    Config.removeBearerToken();
    localStorage.removeItem("email");
    localStorage.removeItem("password");
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
