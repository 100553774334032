interface DealsDataTableRow {
  category: string;
  store: string;
  imageurl: string;
  name: string;
  asin: string;
  finalPrice: string;
  amazonPrice: string;
  walmartPrice: string;
  updatedAt: number;
}

const dealsTableData = {
  columns: [
    { Header: "store", accessor: "store", width: "10%" },
    { Header: "image", accessor: "imageurl", width: "7%" },
    { Header: "name", accessor: "name", width: "10%" },
    { Header: "asin", accessor: "asin", width: "7%" },
    { Header: "retail price", accessor: "finalPrice", width: "7%" },
    { Header: "amazon price", accessor: "amazonPrice", width: "7%" },
    { Header: "walmart price", accessor: "walmartPrice", width: "7%" },
    { Header: "updated at", accessor: "updatedAt", width: "7%" },
  ],
  rows: [] as DealsDataTableRow[], // Explicitly type the rows array
};

export default dealsTableData;
